import Blank from "@/components/Routes/BlankRoute";

export default [
    {
        path: '/trade/order',
        meta: {
            title: "充值管理"
        },
        component: Blank,
        children:[
            {
                path: "game-recharge",
                name: "GameRechargeOrderList",
                meta: {
                    title: "游戏充值订单",
                },
                component: () => import ("@/views/order/gameRecharge/List")
            },
            {
                path: "wallet-recharge",
                name: "WalletRechargeOrderList",
                meta: {
                    title: "钱包充值订单",
                },
                component: () => import ("@/views/order/walletRecharge/List")
            },
            {
                path: "game-recharge-refund",
                name: "gameRechargeRefundList",
                meta: {
                    title: "游戏退款订单",
                },
                component: () => import ("@/views/order/gameRechargeRefund/List")
            },
            {
                path: "fuyou-move",
                name: "fuYouMoveAmount",
                meta: {
                    title: "订单分账",
                },
                component: () => import ("@/views/order/fuYouMove/MoveAmount")
            },
            {
                path: "fuyou-move-list",
                name: "fuYouMoveList",
                meta: {
                    title: "分账记录",
                },
                component: () => import ("@/views/order/fuYouMove/List")
            },
        ]
    },
    {
        path: '/trade/user-white',
        name: "TradeUserWhiteList",
        meta: {
            title: "白名单管理"
        },
        component: () => import("@/views/user/white/List")
    },
    {
        path: '/trade/coupon',
        name: "TradeCouponList",
        meta: {
            title: "优惠券管理"
        },
        component: () => import("@/views/coupon/index/List")
    },
    {
        path: '/trade/user-coupon',
        name: "TradeUserCouponList",
        meta: {
            title: "用户优惠券管理"
        },
        component: () => import("@/views/coupon/user/List")
    },
    {
        path: '/trade/miniGameData',
        name: "MiniGameDataList",
        meta: {
            title: "小游戏数据"
        },
        component: () => import("@/views/data/miniGameData/List")
    },
]
