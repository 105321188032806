export default [
    {
        path: '/user/index',
        name: "UserIndexList",
        meta: {
            title: "用户列表"
        },
        component: () => import("@/views/user/index/List")
    },
    {
        path: '/user/user-level',
        name: "UserLevelList",
        meta: {
            title: "用户等级"
        },
        component: () => import("@/views/user/level/List")
    },
    {
        path: '/user/promote-log',
        name: "UserPromoteLogList",
        meta: {
            title: "渠道补链记录"
        },
        component: () => import("@/views/user/promoteLog/List")
    },
    {
        path: '/user/wallet',
        name: "UserWalletList",
        meta: {
            title: "用户钱包管理"
        },
        component: () => import("@/views/user/wallet/List")
    },
    {
        path: '/user/wallet-log',
        name: "UserWalletLogList",
        meta: {
            title: "用户钱包明细"
        },
        component: () => import("@/views/user/walletLog/List"),
    },
    {
        path: '/user/withdraw',
        name: "UserWithdrawList",
        meta: {
            title: "用户提现管理"
        },
        component: () => import("@/views/user/withdraw/List")
    },
    {
        path: '/user/settle',
        name: "UserSettleList",
        meta: {
            title: "用户结算明细"
        },
        component: () => import("@/views/user/settle/List")
    },
    {
        path: "/user/sham-list",
        name: "userShamList",
        meta: {
            title: "钓鱼库",
        },
        component: () => import("@/views/user/sham/List"),
    },
    {
        path: "/user/settlement",
        name: "userSettlement",
        meta: {
            title: "结算关系",
        },
        component: () => import("@/views/user/settlement/List"),
    },
    {
        path: "/user/changeBinding",
        name: "userChangeBinding",
        meta: {
            title: "结算关系换绑记录",
        },
        component: () => import("@/views/user/changeBindList/List"),
    },
    {
        path: "/user/searchRecords",
        name: "userSearchRecordsList",
        meta: {
            title: "用户搜索记录",
        },
        component: () => import("@/views/user/searchRecords/List"),
    },
    {
        path: "/config/vipCustomer",
        name: "ConfigVipCustomer",
        meta: {
            title: "VIP客服配置",
        },
        component: () => import("@/views/config/VipCustomer.vue"),
    },
]
